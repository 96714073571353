import React, { Component, Fragment } from 'react';
import Modal from 'react-modal';
import www_icon from '../assets/www_icon.png';
import github_circle from '../assets/github_circle.png';

import img_todoapp from '../assets/projects/todoapp.png';
import img_c19 from '../assets/projects/c19.png';
import img_3reviews from '../assets/projects/3reviews.png';
import img_my_reads from '../assets/projects/my_reads.png';
import img_would_you_rather from '../assets/projects/would_you_rather.png';
import img_mathPlay from '../assets/projects/mathPlay.png';
import img_learnMath from '../assets/projects/learnMath.png';
import img_ChineseStream from '../assets/projects/ChineseStream.png';
import img_udacityCards from '../assets/projects/udacityCards.png';
import img_lukeguanCom from '../assets/projects/lukeguanCom.png';
import img_readables from '../assets/projects/readables.png';
import img_amazon_new_releases from '../assets/projects/amazon_new_releases.png';
import img_amazon_new_releases_app from '../assets/projects/amazon_new_releases_app.png';
import img_simon_says_read_app from '../assets/projects/simon_says_read_app.png';
import img_url_shortener from '../assets/projects/url_shortener.png';
import img_npm_logo_red from '../assets/projects/npm_logo_red.png';
import img_project_star from '../assets/projects/projectstar.png';
import img_eks from '../assets/projects/eks.png';
import img_awseks_visio from '../assets/projects/awseks_visio.png';

const projectModalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        width: '70%',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const projectLists = [
    'TodoApp',
    'projectStar',
    '3reviews',
    'myReads',
    'AWS_Cognito_React_UI',
    'would_you_rather',
    'mathPlay',
    'learnMath',
    'ChineseStream',
    'udacityCards',
    'lukeguanCom',
    'readables',
    'amazon_new_releases',
    'amazon_new_releases_app',
    'simon_says_read_app',
    'awseks',
    'c19',
    'url_shortener',
];
const projectListsState = projectLists.reduce((accumulator, element) => {
    accumulator[element] = false;
    return accumulator;
}, {});
const projectObject = {
    projectStar: {
        title: 'Project Star',
        img: img_project_star,
        website: 'https://project-star.lukeguan.com',
        github: '',
        description: [
            <p>Project Star</p>,
            <p>TV and movie filming locations</p>,
            <p>Display the set locations on a map.</p>,
        ],
        techStack:
            'Map, Amazon Cognito, AWS CDK, AWS AppSync, AWS CloudFront, AWS Lambda, AWS S3, DynamoDB, Amazon CloudWatch, AWS IAM, CSS, HTML',
    },
    awseks: {
        title: 'AWS EKS',
        img: img_eks,
        website: 'https://lukeguan.com',
        github: '',
        description: [
            <p>AWS EKS</p>,
            <p>Automating and Running EKS</p>,
            <p>
                Learning about Kubernetes and having it deployed through
                Infrastructure as Code (Flux).
            </p>,
            <img src={img_awseks_visio} alt="awseks_visio" />,
        ],
        techStack: 'AWS EKS, Docker, Github, EC2',
    },
    TodoApp: {
        title: 'Todo App',
        img: img_todoapp,
        website: 'https://todo.lukeguan.com',
        github: '',
        description: [
            <p>Todo App</p>,
            <p>Todo App that scales to the moon!</p>,
            <p>
                The App is built with serverless infrastructure in mind.
                Everything from the front-end to the backend is built with AWS
                CDK. The front end is built with React, CloudFront with S3
                origin. Using react-query to connect with AWS AppSync (GraphQL).
                Manually wrote my own VTL templates to integrate with Single
                Table DynamoDB design. Built my own Stripe Webhook with API
                Gateway that charges user for their subscription. For more
                details, visit:{' '}
                <a href="https://blog.lukeguan.com">
                    https://blog.lukeguan.com
                </a>
                .
            </p>,
        ],
        techStack:
            'Stripe, Amazon Cognito, AWS CDK, AWS AppSync, API Gateway, AWS CloudFront, AWS Lambda, AWS S3, DynamoDB, AWS Secrets Manager, Amazon EventBridge, Amazon SQS, Amazon CloudWatch, AWS IAM, CSS, HTML',
    },
    c19: {
        title: 'Coronavirus Project',
        img: img_c19,
        website: 'https://c19.lukeguan.com',
        github: '',
        description: [
            <p>Coronavirus Project</p>,
            <p>
                Using AWS Lambda in conjuction with AWS CloudWatch to pick up
                daily at 9PM the CSV from{' '}
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://github.com/CSSEGISandData/COVID-19"
                >
                    John Hopkins data
                </a>
                . The data is re-parse and stored into S3.
            </p>,
            <p>Use GeoJSON with Leaflet to display the data onto a map.</p>,
        ],
        techStack: 'AWS Lambda, AWS S3, AWS CloudFront, CSS, HTML, JavaScript',
    },
    AWS_Cognito_React_UI: {
        title: 'AWS Cognito React UI',
        img: img_npm_logo_red,
        website: 'https://dev.to/lukeguan/aws-cognito-react-ui-2hbf',
        github: 'https://github.com/luke-guan/aws-cognito-core-ui',
        description: [
            <p>
                AWS Cognito Core UI is a library package that allows designers
                to customize the UX to work with AWS Cognito.
            </p>,
            <p>
                Open Source NPM JS project. Written with React Hooks and
                TypeScript in mind.
            </p>,
            <p>
                aws-cognito-core-ui{' '}
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.npmjs.com/package/aws-cognito-core-ui"
                >
                    npm
                </a>{' '}
                |{' '}
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://github.com/luke-guan/aws-cognito-core-ui"
                >
                    github
                </a>
            </p>,
            <p>
                aws-cognito-react-ui{' '}
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.npmjs.com/package/aws-cognito-react-ui"
                >
                    npm
                </a>{' '}
                |{' '}
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://github.com/luke-guan/aws-cognito-react-ui"
                >
                    github
                </a>
            </p>,
            <p>
                aws-cognito-react-native-ui{' '}
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.npmjs.com/package/aws-cognito-react-native-ui"
                >
                    npm
                </a>{' '}
                |{' '}
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://github.com/luke-guan/aws-cognito-react-native-ui"
                >
                    github
                </a>
            </p>,
        ],
        techStack:
            'AWS Cognito, AWS Amplify, React, React Hooks, React Native, CSS, HTML, TypeScript',
    },
    '3reviews': {
        title: '3 Reviews',
        img: img_3reviews,
        website: 'https://www.t3ch.link',
        github: '',
        description: [
            <p>3 Reviews</p>,
            <p>
                The website presents 3 options for the visitor to decide and
                pick. Personally learned a little more about some of the
                features in each category.
            </p>,
            <p>
                The site was designed to be web responsive to adapt to mobile
                and desktop displays.
            </p>,
            <p>Purpose was to learn about CSS Flexbox and Grid.</p>,
        ],
        techStack:
            'AWS S3, AWS CloudFront, Route 53, CSS, HTML, JavaScript, React',
    },
    myReads: {
        title: 'My Reads',
        img: img_my_reads,
        website: 'https://myreads.lukeguan.com',
        github: 'https://github.com/luke0b1000/udacity_myReads',
        description: [
            <p>Udacity React Nanodegree course (Project 1) </p>,
            <p>
                In the MyReads project, I have created a bookshelf app that
                allows the user to select and categorize books as “have read”,
                “are currently reading”, or “want to read”.
            </p>,
            <p>
                This web application was the first project from the Udacity
                course. The book data is fetched asynchronously from Udacity
                API. It loads the books into 3 bookshelves: read, want to read,
                and currently reading. The user can add books to any of the
                three bookshelves and move books across the bookshelves.
            </p>,
            <p>
                I learned about React states and that sites will re-render on a
                state change. I now understand how to create responsive dynamic
                sites with react-router-dom.
            </p>,
            <p>
                I use a CloudFormation template to take code from my github
                which then feeds to code pipeline and build using EC2 and stored
                into S3. CloudFront was added as an overlay to provide a better
                user experience.
            </p>,
        ],
        techStack: 'AWS, CSS, HTML, JavaScript, React',
    },
    would_you_rather: {
        title: 'Would You Rather?',
        img: img_would_you_rather,
        website: 'https://wouldyourather.lukeguan.com',
        github: 'https://github.com/luke0b1000/udacity_WouldYouRather',
        description: [
            <p>Udacity React Nanodegree course (Project 2)</p>,
            <p>
                A user can login and submit responses to the number of different
                Would You Rather questions that have been asked. A user can also
                ask new questions to be answered.
            </p>,
            <p>
                The application uses React with Redux. Implement different redux
                store so that different React components can retrieve the
                application state and render in real time.
            </p>,
            <p>
                I use a CloudFormation template to take code from my github
                which then feeds to CodePipeline and build using EC2 and stored
                into S3. CloudFront was added as an overlay to provide a better
                user experience.
            </p>,
        ],
        techStack: 'AWS, CSS, HTML, JavaScript, React, Redux',
    },
    mathPlay: {
        title: 'Math Play',
        img: img_mathPlay,
        website: 'https://mathplay.lukeguan.com',
        github: 'https://github.com/luke0b1000/aws_mathPlay',
        description: [
            <p>
                The objective is for the user to practice basic math skills.
                Random numbers are generated for the selected mathematical
                operand. The user then produces the answer. The number and
                symbol are displayed using MathJax.
            </p>,
            <p>
                I use a CloudFormation template to take code from my github
                which then feeds to CodePipeline and build using EC2 and stored
                into S3. CloudFront was added as an overlay to provide a better
                user experience.
            </p>,
        ],
        techStack: 'AWS, CSS, HTML, JavaScript, React, Redux',
    },
    learnMath: {
        title: 'Learn Math',
        img: img_learnMath,
        website: 'https://learnmath.lukeguan.com',
        github: 'https://github.com/luke0b1000/learnMath',
        description: [
            <p>
                A site to practice basic arithmetic and keep track of the user's
                progress. I started creating this site with the intention of
                answering the questions inside chrome developer tools.
            </p>,
            <p>
                I use JavaScript console.log to present the questions and
                functions to answer the questions. I then use jQuery to create
                HTML and DOM elements based on the internal functions. The next
                step was to create a React version, which is what spawned Math
                Play. The next version will be in React Native.
            </p>,
        ],
        techStack: 'AWS S3, CSS, HTML JavaScript',
    },
    ChineseStream: {
        title: 'Chinese Stream',
        img: img_ChineseStream,
        website:
            'https://play.google.com/store/apps/developer?id=Luke+Guan&hl=en_US',
        github: 'https://github.com/luke0b1000/chineseStream',
        description: [
            <p>
                Since I routinely listen to streaming Chinese radio. I decided
                to create an app to facilitates that. I use the expo API to
                interface with internet radio stations. This was my first
                attempt at Internationalization (i18n), with the language
                defaulting to Traditional Chinese with the option to switch to
                English. This app is currently available on the Google Play
                Store. I will eventually also deploy to the Apple App Store when
                I have a few more apps.
            </p>,
            <p>
                *Personally, I have also automated capturing the streams of the
                radio stations and splitting them up by the hour and uploading
                to Google Drive for private offline listening.
            </p>,
        ],
        techStack: 'React Native, Android, iOS',
    },
    udacityCards: {
        title: 'Udacity Cards',
        img: img_udacityCards,
        website: 'https://expo.io/@lukeguan/udacity_flashcards',
        github: 'https://github.com/luke0b1000/udacity_flashcards',
        description: [
            <p>Udacity React Nanodegree course (Project 3)</p>,
            <p>
                For the Udacity Cards project, I built a mobile application that
                allows the user to study collections of flashcards. This app
                allows the user to create different categories of flashcards
                called "decks", add flashcards to those decks, then take quizzes
                with those decks.
            </p>,
        ],
        techStack: 'React Native, Android, iOS',
    },
    lukeguanCom: {
        title: 'LukeGuan.com',
        img: img_lukeguanCom,
        website: 'https://lukeguan.com',
        github: 'https://github.com/luke0b1000/lukeguanCom',
        description: [
            <p>My personal website to display my projects</p>,
            <p>
                A CloudFormation template was used to take code from my github
                which then feeds to CodePipeline and build using EC2 and stored
                into S3. CloudFront was added as an overlay to give a better
                user experience.
            </p>,
        ],
        techStack: 'AWS, CSS, HTML, JavaScript, React, Redux',
    },
    readables: {
        title: 'Readables',
        img: img_readables,
        github: 'https://github.com/luke0b1000/udacity_Readables',
        description: [
            <p>
                Udacity React Nanodegree course (Project 2, extra-credit
                [optional])
            </p>,
            <p>
                Users are able to post content to predefined categories, comment
                on their posts and other users' posts, and vote on posts and
                comments. Users are also be able to edit and delete posts and
                comments. The objective was to emulate a simple version of
                Reddit/Hacker News. Udacity setup a local backend server
                (https://github.com/udacity/reactnd-project-readable-starter)
                that emulated a remote API. The goal was to combine React with
                Redux to mimic requests to the API for data. I started by
                creating the site with very little redux and was heavy on
                requesting the data live. I learned quite a bit about the role
                of Redux without implementing Redux. I then progressed to
                implementing Redux as required by the course.
            </p>,
        ],
        techStack: 'AWS, CSS, HTML, JavaScript, React, Redux',
    },
    amazon_new_releases: {
        title: 'Amazon New Releases',
        img: img_amazon_new_releases,
        description: [
            <p>
                This website displayed new releases on Amazon during the
                selected week, with the releases separated into the categories
                of DVDs, Books, Music, and Games.
            </p>,
            <p>
                At first, I used Amazon API protocols to retrieve the data and
                store it into MySQL. PHP was used as both backend and frontend
                to display the items that were being released that week.
            </p>,
            <p>
                Eventually I switched to using the webcrawler SCRAPY to crawl
                Amazon.com for newly released items and store them in mongoDB.
                The django web framework was used as the frontend to display the
                new items.
            </p>,
        ],
        techStack: 'PHP, MySQL, Django, mongoDB, CSS, HTML',
    },
    amazon_new_releases_app: {
        title: 'Amazon New Releases App',
        img: img_amazon_new_releases_app,
        website:
            'https://play.google.com/store/apps/developer?id=Luke+Guan&hl=en_US',
        description: [
            <p>
                Using Scrapy, a python web crawler to crawl Amazon for new
                releases and store the results into AWS DynamoDB.
            </p>,
            <p>
                React Native framework combined with Amazon AppSync and GraphQL
                pulls the data from DynamoDB and projects the data into the
                mobile app.
            </p>,
            <p>
                *Learn that Amazon has captcha enabled, so I increased time and
                used proxy per scrape.
            </p>,
        ],
        techStack:
            'Python, Scrapy, React Native, Redux, iOS, Android, AWS DynamoDB, Amazon AppSync, GraphQL, AWS Amplify',
    },
    simon_says_read_app: {
        title: 'Simon Says Read App - Simon and Schuster',
        img: img_simon_says_read_app,
        website:
            'https://play.google.com/store/apps/details?id=com.lukeguan.simonsaysread&hl=en_US',
        description: [
            <p>
                Using Scrapy, a python web crawler to crawl Amazon for new
                releases and store the results into AWS DynamoDB. Scrapped over
                160GB of information.
            </p>,
            <p>
                React Native framework combined with Amazon AppSync and GraphQL
                pulls the data from DynamoDB and projects the data into the
                mobile app.
            </p>,
        ],
        techStack:
            'Python, Scrapy, React Native, Redux, iOS, Android, AWS DynamoDB, Amazon AppSync, GraphQL, AWS Amplify',
    },
    url_shortener: {
        title: 'URL Shortener - Coming Soon',
        img: img_url_shortener,
        description: [
            <p>
                Update: Fall 2021, AWS Architech Diagram:
                https://visio.lukeguan.com
            </p>,
            <p>
                Update: Summer 2021, Learning to build Multi-Tenant SaaS in AWS.
            </p>,
            <p>
                A URL Shortener with basic functionality on AWS serverless
                infrastructure (allowing for scalability). Users provide a
                domain, and the short aliases redirect to the long URLs. Similar
                to bit.ly and tinyurl.com.
            </p>,
            <p>
                I used Facebook's React library for the front end and Amazon Web
                Services for the backend.
            </p>,
            <p>
                The backend was created in NodeJS and uses various AWS
                infrastructure such as Cognito, DynamoDB, Lambda, and API
                Gateway, as this is a completely serverless architecture.
            </p>,
            <p>
                The monthly subscription is handled by the Stripe Payment
                System.
            </p>,
            <p>
                *Learn to integrate a lot of different AWS products and
                services.
            </p>,
        ],
        techStack:
            'Amazon Cognito, AWS Fargate, Amazon Elastic Container Registry, DynamoDB, Amazon API Gateway, AWS Step Functions, AWS Lambda, AWS Aurora, Amazon Route 53, Amazon CloudFront, AWS IAM, S3, AWS AppSync, Terraform, Docker, Stripe Payment System',
    },
};
class MyProjects extends Component {
    state = {
        ...projectListsState,
    };
    openProjectModal = (projectID) => {
        this.setState({ [projectID]: true });
    };
    closeProjectModal = (projectID) => {
        this.setState({ [projectID]: false });
    };
    eachProject = (projectID) => {
        return (
            <article key={projectID} className="project-box">
                <a
                    href={`#${projectID}`}
                    onClick={() => this.openProjectModal(projectID)}
                >
                    <div className="container">
                        <img
                            className="image"
                            src={projectObject[projectID].img}
                            alt={projectID}
                        />
                        <div className="middle">
                            <div className="mytext">
                                {projectObject[projectID].title}
                            </div>
                        </div>
                    </div>
                </a>
                <Modal
                    isOpen={this.state[projectID]}
                    onRequestClose={() => this.closeProjectModal(projectID)}
                    style={projectModalStyles}
                    ariaHideApp={false}
                >
                    <h1 key={projectID}>{projectObject[projectID].title}</h1>
                    {projectObject[projectID].website ? (
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={projectObject[projectID].website}
                        >
                            <img src={www_icon} alt="www_icon" />
                        </a>
                    ) : null}
                    {projectObject[projectID].github ? (
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={projectObject[projectID].github}
                        >
                            <img
                                key={projectID}
                                src={github_circle}
                                alt="github_circle"
                            />
                        </a>
                    ) : null}
                    <div>{projectObject[projectID].description}</div>
                    <p className="techStack">
                        {projectObject[projectID].techStack}
                    </p>
                </Modal>
            </article>
        );
    };
    render() {
        const showProject = projectLists.map((element) => {
            return this.eachProject(element);
        });
        return (
            <Fragment>
                <div className="projects-title">My Projects</div>
                <div className="projects">{showProject}</div>
            </Fragment>
        );
    }
}

export default MyProjects;
